.lottery-page{
  background: rgb(175,0,43);
  background: linear-gradient(180deg, rgba(175,0,43,1) 0%, rgba(66,0,18,1) 100%);
}

.lottery-page .lottery-sec{
  position: relative;
  padding: 200px 0 100px;
}
.lottery-page .lottery-sec .filter-sec {
  position: relative;
    background-color: #570118;
    border-radius: 100px;
    clear: both;
    float: left;
    width: 100%;
}

.lottery-page .lottery-sec .filter-sec h3 {
  font-size: 23px;
  color: #FFC400;
  font-weight: 700;
  padding: 25px 30px;
  float: left;
}

.lottery-page .lottery-sec .filter-sec .filters {
  float: left;
    width: 69%;
    padding-left: 50px;
}

.lottery-page .lottery-sec .filter-sec .filters .filters-nav {
  display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.lottery-page .lottery-sec .filter-sec .filters .filters-nav li {
  margin-right: 90px;
  position: relative;
}

.lottery-page .lottery-sec .filter-sec .filters .filters-nav li a {
  color: #fff;
  font-size: 18px;
  padding:27px 0 18px;
  display: block;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
}
.lottery-page .lottery-sec .filter-sec .filters .filters-nav li a:hover{
  color: #FFC400;
}
.lottery-page .lottery-sec .filter-sec .filters .filters-nav li.dropdown .submenu {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 100%;
  min-width: 237px;
  background-color: #570118;
  padding:15px;
  border-radius: 0 0 15px 15px;
  box-shadow: 7px 11px 15px rgb(0 0 0 / 10%);
  z-index: 99;
}
.lottery-page .lottery-sec .filter-sec .filters .filters-nav li:hover .submenu{
  visibility: visible;
  opacity: 1;
}
.lottery-page .lottery-sec .filter-sec .filters .filters-nav li .submenu li{
  padding-bottom: 5px;
  margin-right: 0;
}
.lottery-page .lottery-sec .filter-sec .filters .filters-nav li .submenu li a{
  padding: 0;
  font-size: 16px;
}
.lottery-page .lottery-sec .filter-sec .btn-style-one {
  float: right;
  margin-top: 18px;
  margin-right: 15px;
}

.lottery-page .price-sec {
  position: relative;
  padding: 100px 0;
}

.lottery-page .price-sec .price-block {
  position: relative;
  min-height: auto;
}

.lottery-page .price-sec .price-block .price-inner {
  position: relative;
    background-color: #fff;
    border-radius: 25px;
    margin: 0 30px 90px;
}
.lottery-page .price-sec .sec-title {
  margin-bottom: 100px;
}

.lottery-page .price-sec .sec-title h2 {
  color: #fff;
}
.loadmore {
  position: relative;
  margin-top: 40px;
}
.lottery-page .price-sec .price-block .price-inner .price-tag {
  position: absolute;
  width: 109px;
  height: 109px;
  background-color: #FFC400;
  border-radius: 50%;
  border: 5px solid #fff;
  top: -48px;
  left: 50%;
  transform: translateX(-50%);
}

.lottery-page .price-sec .price-block .price-inner .price-tag h4 {
  font-size: 49px;
  font-weight: 700;
  font-family: 'Orbitron', sans-serif;
  color: #000000;
}

.lottery-page .price-sec .price-block .price-inner .price-tag p {
  position: relative;
  color: #000;
  font-size: 19px;
  top: -13px;
  font-family: 'Source Sans Pro', sans-serif;
}

.lottery-page .price-sec .price-block .price-inner .text-block {
  padding: 85px 30px 40px;
}

.lottery-page .price-sec .price-block .price-inner .text-block h3 {
  font-size: 48px;
  color: #AF002B;
  font-weight: 700;
  font-family: 'Source Sans Pro', sans-serif;
  position: relative;
}

.lottery-page .price-sec .price-block .price-inner .text-block h3:before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -9px;
  height: 5px;
  width: 44%;
  background-color: #FFC400;
  transform: translateX(-50%);
}

.lottery-page .price-sec .price-block .price-inner .text-block p {
  font-size: 21px;
    color: #420012;
    margin: 19px 0 10px;
    font-family: 'Source Sans Pro', sans-serif;
}

.lottery-page .price-sec .price-block .price-inner .text-block .price {
  font-size: 41px;
  color: #AF002B;
  font-weight: 600;
  font-family: 'Source Sans Pro', sans-serif;
}

.lottery-page .price-sec .price-block .price-inner .text-block .detail-area {
  margin:13px 0 0px;
}

.lottery-page .price-sec .price-block .price-inner .text-block .detail-area li {
  font-size: 18px;
  color: #420012;
  font-family: 'Source Sans Pro', sans-serif;
  margin-bottom: 7px;
}

.lottery-page .price-sec .price-block .price-inner .text-block .btn-style-two {
  margin-top: 25px;
  display: inline-block;
}









.ticket-popup {
  max-width: 688px !important;
}
.ticket-popup .modal-content{
  background-image: url('../../static/images/landing/ticket-bg2.png');
  background-color: transparent;
    border: none;
    background-size: 100%;
    background-repeat: no-repeat;
}

.ticket-popup .modal-content .close {
    width: 32px;
    height: 32px;
    border: 1px solid #AF002B;
    border-radius: 50%;
    text-align: center;
    padding: 1px;
    margin: 0 0 0 0;
    line-height: 1;
    font-size: 26px;
    opacity: 1;
    color: #AF002B;
    position: absolute;
    top: 35px;
    right: 43px;
    z-index: 999;
    display: block;
}

.ticket-popup .modal-content .close span {
    line-height: 0;
    position: relative;
    top: -2px;
}

.ticket-popup .modal-content .modal-header {
    border: none;
}

.ticket-popup .modal-content .modal-upper-pad {
    position: relative;
    text-align: left;
}


.ticket-popup .modal-content .form-modal-landing-nft {
  position: relative;
    top: -32px;
    display: flex;
    flex-wrap: wrap;
}

.ticket-popup .modal-content .form-modal-landing-nft .left-content-area {
  width: calc(70% - 0px);
  text-align: center;
  padding: 30px 39px;
  position: relative;
}

.ticket-popup .modal-content .form-modal-landing-nft .left-content-area:before {
  content: "";
  position: absolute;
    right: 0;
    top: 19px;
    width: 8px;
    height: 100%;
    opacity: 0.3;
  background-image: url('../../static/images/landing/border.png');
}
.ticket-popup .modal-content .form-modal-landing-nft .left-content-area h2 {
  font-size: 32px;
    color: #AF002B;
    font-family: 'Orbitron', sans-serif;
    margin-bottom: 15px;
    font-weight: 700;
}
.ticket-popup .modal-content .form-modal-landing-nft .left-content-area h5 {
  font-size: 21px;
  font-weight: 600;
  margin: 18px 0 22px;
  color: #AF002B;
}
.ticket-popup .modal-content .form-modal-landing-nft .left-content-area p {
  font-size: 16px;
  color: #420012;
  margin-bottom: 31px;
}

.ticket-popup .modal-content .form-modal-landing-nft .right-content-area {
  width: calc(30% - 0px);
  padding: 21px 25px 0;
}

.ticket-popup .modal-content .form-modal-landing-nft .right-content-area .content-box {
  position: relative;
  margin-bottom: 20px;
}

.ticket-popup .modal-content .form-modal-landing-nft .right-content-area .content-box h4 {
  font-size:21px;
  font-weight: 700;
  color: #AF002B;
}

.ticket-popup .modal-content .form-modal-landing-nft .right-content-area .content-box p {
  color: #420012;
  font-size: 16px;
  font-weight: 600;
}


@media (max-width: 1599px){
  .lottery-page .lottery-sec .filter-sec .filters{
      width: 52%;
  }
  .lottery-page .lottery-sec .filter-sec .filters .filters-nav li{
    margin-right: 50px;
  }
}
@media (max-width: 1299px){
  .lottery-page .lottery-sec .filter-sec .filters{
      width: 50%;
  }
  .lottery-page .lottery-sec .filter-sec .filters .filters-nav li{
    margin-right: 30px; 
  }
  .lottery-page .price-sec .price-block .price-inner{
    margin: 0 0px 90px;
  }
}

@media (max-width: 1199px){
  .lottery-page .lottery-sec .filter-sec .filters {
      width:56%;
      padding: 0;
  }
  .lottery-page .lottery-sec .filter-sec .filters .filters-nav li{
    margin-right: 30px; 
  }
  .lottery-page .lottery-sec .filter-sec h3{
    padding: 25px 20px;
    font-size: 20px;
  }
}
@media (max-width: 991px){
  .lottery-page .lottery-sec .filter-sec .filters .filters-nav li.dropdown:last-child .submenu{
    left: auto;
    right: 0;
    min-width: 213px;
  }
  .lottery-page .lottery-sec .filter-sec{
    border-radius: 0;
  }
  .lottery-page .lottery-sec .filter-sec h3{
    float: none;
    width: 100%;
    text-align: left;
  }
  .lottery-page .lottery-sec .filter-sec .filters {
      padding: 0;
      float: none;
      width: 100%;
  }
  .lottery-page .lottery-sec .filter-sec .btn-style-one{
    margin-right: 0;
    position: absolute;
    float: none;
    top: 14px;
    right: 11px;
    margin: 0;
  }
  .lottery-page .lottery-sec .filter-sec .filters .filters-nav li a{
    padding: 11px 0 18px;
  }
  .lottery-page .lottery-sec .filter-sec .filters .filters-nav{
    justify-content: space-between;
    padding: 0 21px;
  }
  .lottery-page .price-sec{
    padding: 100px 0 0;
  }
}
@media (max-width: 539px){
  .footer-sec .footer-widget{
    margin-bottom: 40px;
    text-align: center;
  }
  .footer-sec .footer-bottom .social-icon ul{
    justify-content: center;
  }
  .lottery-page .lottery-sec .filter-sec .filters .filters-nav li {
      margin-right: 0;
  }
}

@media (max-width: 460px){
  .lottery-page .lottery-sec .filter-sec .filters .filters-nav li a,
  .lottery-page .lottery-sec .filter-sec .filters .filters-nav li .submenu li a{
    font-size: 14px;
  }
  .lottery-page .lottery-sec .filter-sec h3{
    font-size: 17px;
  }
  .lottery-page .lottery-sec .filter-sec .btn-style-one{
    padding: 11px 22px;
    font-size: 15px;
  }
  .lottery-page .lottery-sec .filter-sec .filters {
      padding: 0;
      float: none;
      justify-content: center;
      display: flex;
      width: 92%;
  }
  .lottery-page .lottery-sec .filter-sec .filters .filters-nav li {
      margin-right: 13px;
  }
}
@media (max-width: 430px){
  .lottery-page .lottery-sec .filter-sec .filters .filters-nav li {
      margin-right: 7px;
  }
  .lottery-page .lottery-sec .filter-sec .filters{
    width: 90%;
  }
  .lottery-page .lottery-sec .filter-sec .filters .filters-nav {
      justify-content: center;
      padding: 0;
  }
  .lottery-page .lottery-sec .filter-sec{
    width: 94%;
  }
  .lottery-page .lottery-sec{
    overflow: hidden;
  }
  .lottery-page .lottery-sec .filter-sec .filters {
      width: 100%;
  }
  .lottery-page .lottery-sec .filter-sec {
      width: 100%;
  }
  .lottery-page .lottery-sec .filter-sec .filters .filters-nav li.dropdown .submenu {
      min-width: 151px !important;
      padding: 10px;
  }
  .lottery-page .lottery-sec .filter-sec .filters .filters-nav li .submenu li a {
      font-size: 12px;
  }
  .lottery-page .price-sec .price-block .price-inner .text-block h3{
    font-size: 40px;
  }
}
@media (max-width: 375px){
  .lottery-page .lottery-sec .filter-sec .filters .filters-nav li .submenu li a{
    font-size: 13px;
  }
  .lottery-page .lottery-sec .filter-sec .filters .filters-nav li a, .lottery-page .lottery-sec .filter-sec .filters .filters-nav li .submenu li a {
      font-size: 12px;
  }
  
}