
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}




.app-text {
    text-align: center;
    color: #fff;
  }
body{
  min-height: 100vh;
    display: block;
    color: #fff!important;
    font-family: 'Source Sans Pro', sans-serif;
}
 .landing-red {
    background-color: #420012;
    min-height: 100vh;
    display: block;
    color: #fff;
    font-family: 'Source Sans Pro', sans-serif;
  }
  a,
  a:hover,
  a:foucs{
    text-decoration: none;
  }
   /* Css For All Modal Same Background And Images Logo Start */
/* .input-feild-button .text-feild-modal .MuiFormControl-root .MuiInputBase-root .MuiOutlinedInput-notchedOutline{
  border: 1px solid #ffffff;
}
.modal-open .form-modal-landing-silver .input-feild-button .button-form-modal{
  background-color: #ffaa06;
  border: none;
  border-radius: 36px;
  color: #fff;
  padding: 10px 55px;
  margin-top: 8px;
}
.modal-open .form-modal-landing-silver .input-feild-button .text-feild-modal .MuiFormControl-root{
  width: 100%;
  
}
.modal-footer{
  display: none!important;
}
.modal-open .modal-header{
  border: none;
}
.modal-open .form-modal-landing-silver .input-feild-button .text-feild-modal .MuiFormControl-root .MuiInputBase-root{
  color: #ffffff;
  border-radius: 50px;
  padding-bottom: 5px;
}

.modal-open .form-modal-landing-silver .input-feild-button .text-feild-modal .MuiFormControl-root label{
  color: #ffffff;
  font-size: 12px;
  padding-top: 5px;
  padding-left: 10px;
  font-family: 'lato';
  font-weight: 400;
 
}
.modal-open .modal-content{
  border-radius: 50px;
  position: relative;
}
.modal-header .close{
  padding: 20px 14px!important;
}
.modal-header button.close{
  opacity: 1;
  color: #ffffff;
  box-shadow: none;
  outline: none;
  text-shadow: none;
  font-weight: 400;
  z-index: 1111;
  font-size: 32px;
}
.modal-header button{
  outline: none;
  box-shadow: none;
}
.modal-open .modal-dialog{
  margin: 230px auto;
  -webkit-box-shadow: 0px 4px 33px 3px rgba(0,0,0,0.60);
  -moz-box-shadow: 0px 4px 33px 3px rgba(0,0,0,0.60);
  shadow: 0px 4px 33px 3px rgba(0,0,0,0.60);
}
.modal-open .modal-body{
  padding-bottom: 50px;
}

.modal-open .form-modal-landing-silver .input-feild-button .text-mid-pr {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.modal-open .form-modal-landing-silver .input-feild-button{
  padding-top: 68px;
}

.modal-open .form-modal-landing-silver .input-feild-button .text-mid-pr p span {
  font-weight: 600;
  font-size: 15px;
}


.modal-open .register-modal {
  position: relative;
  border-radius: 50px;
}
.modal-open .register-modal .icon-area-modal-pur{
  position: absolute;
    top: -2px;
    left: 163px;
}
.modal-open .register-modal .icon-area-modal-pur img{
  width: 54%;
  padding-top: 40px;
  text-align: center;
}

.modal-open .register-modal .icon-area-modal-pur{
  text-align: center;
}
.modal-open .modal-dialog{
  margin: 182px auto;
  max-width: 662px;
}
.modal-open .modal-content{

 background-size: cover;
 width: 100%;
 border: none;
 background-position: top center;
} */